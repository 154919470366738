footer{
    background-color: #056CF2;
    width: 100%;
    margin: 0;
    color: #ffffff;
}

.footerWrapper{
    max-width: 920px;
    width: calc(100% - 10px);
    margin: 0 auto;
    position: relative;
}
.footerWrapper .logo{
    font-size: 18px;
    margin: 0;
    font-family: kan415typos-std, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    line-height: 50px;
}
.footerWrapper address{
    font-style: normal;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 30px;
}
.footerWrapper .logo .title{
    font-size: 28px;
    display: inline;
}
.footerWrapper .buttonWrapper{
    position: absolute;
    top: 50px;
    right: 0;
    display: inline;
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
}
.footerWrapper .cap{
    font-size: 14px;
    line-height: 1.2;
}
@media screen and (max-width: 500px) {
    .footerWrapper .buttonWrapper{
        position: relative;
        top: auto;
        margin: 20px auto;
        text-align: center;
        display: block;
    }
    .footerWrapper address{
        margin-bottom: 10px;
    }
}