
@-webkit-keyframes ani-mouse {
  0% {
  opacity: 1;
  top: 29%;
  }
  15% {
  opacity: 1;
  top: 50%;
  }
  50% {
  opacity: 0;
  top: 50%;
  }
  100% {
  opacity: 0;
  top: 29%;
  }
}
@-moz-keyframes ani-mouse {
  0% {
  opacity: 1;
  top: 29%;
  }
  15% {
  opacity: 1;
  top: 50%;
  }
  50% {
  opacity: 0;
  top: 50%;
  }
  100% {
  opacity: 0;
  top: 29%;
  }
}
@keyframes ani-mouse {
  0% {
  opacity: 1;
  top: 29%;
  }
  15% {
  opacity: 1;
  top: 50%;
  }
  50% {
  opacity: 0;
  top: 50%;
  }
  100% {
  opacity: 0;
  top: 29%;
  }
}
.scrollBtnWrapper{
  height: 120px;
  width: 100%;
}
 .scrollBtn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}
.scrollBtn p{
    margin: 0;
}
.scrollBtn > * {
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  color: #57AAF2;
}
.scrollBtn > *:hover,
.scrollBtn > *:focus,
.scrollBtn > *.active {
  color: #57AAF2;
}
.scrollBtn > *:hover,
.scrollBtn > *:focus,
.scrollBtn > *:active,
.scrollBtn > *.active {
  filter: alpha(opacity=80);
}
.scrollBtn .mouse {
  position: relative;
  display: block;
  width: 35px;
  height: 55px;
  margin: 0 auto 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid #57AAF2;
  border-radius: 23px;
}
.scrollBtn .mouse > * {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  background: #57AAF2;
  border-radius: 50%;
  -webkit-animation: ani-mouse 2.5s linear infinite;
  -moz-animation: ani-mouse 2.5s linear infinite;
  animation: ani-mouse 2.5s linear infinite;
}