body {
  margin: 0;
  font-family: fot-tsukuardgothic-std, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
html{
  scroll-behavior: smooth;
  scroll-padding-top: 55px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 p, a{
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 30px;
  color: #404040;
  text-decoration: none;
  line-height: 1.5;
}
h2, h3{
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
}
img{
  width: 100%;
}
li{
  list-style: none;
}
.sp{
  display: none;
}
.pc{
  display: inline-block;
}
.txt_c{
  text-align: center;
}
.mar-b30{
  margin: 0 auto 30px;
}
@media screen and (max-width: 500px) {
  .sp{
    display: inline-block;
  }
  .pc{
    display: none;
  }
  .block{
    display: inline-block;
  }
}