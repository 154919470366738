header{
    height: 60px;
    margin: 0 auto;
    position: relative;
    z-index: 12;
    background-color: #ffffff;
}
header h1{
    font-size: 18px;
    margin: 0;
    font-family: kan415typos-std, sans-serif;
    font-weight: 400;
    font-style: normal;
    height: 60px;
    line-height: 1;
    display: inline-block;
    line-height: 50px;
}
header .headerWrapper{
    max-width: 1050px;
    margin: 0 auto;
    height: 60px;
    z-index: 10;
}
header h1 .title{
    font-size: 28px;
    display: inline;
}
header .contactButtonWrapper{
    float: right;
    display: inline;
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
}
header .contactButtonWrapper .contactButtonTel{
    display: inline;
    color: #056CF2;
    font-size: 24px;
}
header .contactButtonWrapper .contactButtonTel img{
    max-width: 250px;
    vertical-align: middle;
}
header .contactButtonWrapper .contactButtonTel svg{
    font-size: 20px;
}
header .contactButtonWrapper .contactButton{
    height: 60px;
width: 250px;
text-align: center;
background: #2E97F2;
color: white;
border: none;
display: inline-block;
vertical-align: middle;
line-height: normal;
line-height: 55px;
}
/* スマホ */
@media screen and (max-width: 500px) {
    header .contactButtonWrapper .contactButton{
        display: none;
    }
    header h1{
        font-size: 0.75rem;
        padding: 5px;
        line-height: 1;
    }
    header h1 .title{
        display: block;
    }
    header .contactButtonWrapper .contactButtonTel{
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        color: #ffffff;
        padding: 0 10px;
        background: #2E97F2;
        margin: 0;
        height: 60px;
        width: calc( 100% - 250px);
        font-size: 12px;
        line-height: 1;
    }
    
    header .contactButtonWrapper .contactButtonTel svg{
        font-size: 28px;
        padding: 5px;
        display: block;
        margin: 0 auto;
    }
  }
  /* タブレット */
  @media screen and (max-width: 800px ) and (min-width: 501px ){
    header h1{
        padding: 0 5px 0 0;
    }
    header .contactButtonWrapper{
        float: right;
        display: inline;
        width: calc( 100% - 340px);
        text-align: center;
        max-width: 300px;
    }
    header .contactButtonWrapper .contactButton{
        display: none;
    }
    header .contactButtonWrapper .contactButtonTel{
        line-height: 60px;
        height: 60px;
        margin: 0;
        color: #ffffff;
        font-weight: 900;
        font-size: 20px;
    }
    header .contactButtonWrapper .contactButtonTel svg{
        font-size: 24px;
        margin-right: 5px;
    }
  }