.topImageWrapper{
    position: relative;
    height: 730px;
    overflow: hidden;
    margin-bottom: 30px;
    width: 100%;
}
.topImageWrapper img{
    height: 730px;
    object-fit: cover;
}
.textBoxWrapper{
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
}

.textBoxWrapper .textBox{
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 5px 0;
}
.textBoxWrapper .textBox p{
    color: #ffffff;
    font-size: 68px;
    font-weight: 900;
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
}
.textBoxWrapper .textBox p.small{
    font-size: 18px;
}
/* タブレット */
@media screen and (max-width: 800px) {
    .textBoxWrapper .textBox{
        padding: 60px 10px 0;
    }
  }
/* スマホ */
@media screen and (max-width: 500px) {
    .topImageWrapper{
        height: 460px;
    }
    .topImageWrapper img{
        height: 460px;
    }
    .textBoxWrapper .textBox p{
        color: #ffffff;
        font-size: 45px;
        font-weight: 900;
    }
    .textBoxWrapper .textBox{
        padding: 60px 0 0;
        margin: 0 10px;
    }
  }

/* メニューバー */
.navbar{
    height: 48px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background : #fffc;
    width: 100%;
}
 .menuBars{
    position: absolute;
    top: 0px;
    left: 0;
    font-size: 28px;
    padding:5px;
}
 .nabvarToggle{
    margin-bottom: 40px;
}
 .navMenu{
    position: fixed;
    overflow-y: scroll;
    top: 0px;
    left: -120%;
    height: 100vh;
    width: 330px;
    background: #056CF2;
    transition: 1000ms;
    z-index: 15;
}
 .navMenu.active{
    left: 0;
    transition: 500ms;
}
 .navMenu .navMenuItem a{
    color: #ffffff;
    font-size: 20px;
    line-height: 3;
    padding: 10px;
}
 .navMenu .navMenuItem a span{
    padding: 0 10px 0 15px;
}
 .navMenu .navMenuItem :hover{
    background-color: #056CF2;
}

/* Contents */
.contents{
    margin-bottom: 90px;
}
.contents h2{
    background-color: #056CF2;
    text-align: center;
    color: #ffffff;
    padding: 5px 0;
    margin: 0 auto 60px;
}
.contents h2 .en{
    display: block;
    margin: 0 auto;
    font-size: 18px;
}
.contents .contentsWrapper{
    
    width: 100%;
    margin: 0 auto 60px;
}

/* Detaile */
#detaileContents{
    opacity: 0;
    transition: 1200ms;
    top: 60px;
    position: relative;
    will-change: transform,animation,position, transition;
}
#detaileContents.active{
    opacity: 1;
    top: 0;
    transition: 1200ms;
}
#detaile .SwipeableViewsWrapper{
    width: 100%;
    overflow: hidden;
}
#detaile .SwipeableViewsWrapper .SwipeableViewsSlider{
    padding: 0 15px;
    width: calc( 100% - 30px );
    overflow: visible;
    max-width: 780px;
    margin: 0 auto;
}
#detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide{
    width: calc( 100% - 30px );
    max-width: 720px;
    display: flex;
    margin-bottom: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    border-radius: 5px;
}
#detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .textBox, #detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .imageBox{
    width: 50%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
border-bottom-left-radius: inherit;
}
#detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .textBox h3{
    margin: 0 auto 10px;
}
#detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .textBox{
    padding: 10px;
}
#detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .imageBox img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: inherit;
border-bottom-left-radius: inherit;
}
@media screen and (max-width: 500px) {
    #detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide{
        display: block;
        height: 450px;
    }
    #detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .textBox, #detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .imageBox{
        width: 100%;
    }
    #detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .textBox{
        padding: 10px;
        width: calc(100% - 20px);
    }
    #detaile .SwipeableViewsWrapper .SwipeableViewsSlider{
        padding: 0 20px;
        width: calc( 100% - 40px );
    }
    #detaile .SwipeableViewsWrapper .SwipeableViewsSlider .slide .imageBox img{
        border-top-left-radius: inherit;
    border-top-right-radius: inherit;
border-bottom-left-radius: 0;
    }
  }

/* ページネーション */
.pagination{
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    padding: 0;
    width: 100%;
}
.dotList{
    display: inline;
    margin:0  2px;
}
.dot{
    border: none;
    width: 20px;/*幅*/
	height: 20px;/*高さ*/
    border-radius: 50%;/*角丸*/
    transition: 500ms;
    margin:3px ;
    background-color:#737373;
    cursor: pointer;
    display: inline;
}
.dot.active{
    background-color: #0D0D0D;
    transition: 500ms;
    border: none;
}
/* Vision */
#vision .visionWrapper{
    max-width: 780px;
    margin: 0 auto　60px60px;
    display: flex;
    position: relative;
}
#vision .visionWrapper .visionWrapperBox{
    width: 50%;
}
#vision .visionWrapper .visionWrapperBox.left{
    display: flex;
    justify-content: center;
}
#vision .visionWrapper .visionWrapperBox.left p{
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-size: 36px;
    font-weight: 900;
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
}
#vision .visionWrapper .visionWrapperBox.right p{
    font-size: 14px;
    font-weight: 900;
    
}
#vision .visionWrapper .canvas{
    width: 20px;
    padding: 0 1px 0 2px;
    margin: 0px 50px ;
    position: relative;
    transition: 1000ms;
}
#vision .visionWrapper .line.top{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #707070;
    width: 2px;
    height: calc(50% - 25px);
}#vision .visionWrapper .line.bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #707070;
    width: 2px;
    height: calc(50% - 25px);
}
#vision .visionWrapper .circle{
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-color: #57AAF2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    content: close-quote;
    z-index: 2;
}
#vision .visionWrapper .box{
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    content: close-quote;
    z-index: 1;
}
#vision .midashi01{
    font-weight: bold;
    font-size: 24px;
}
#vision .midashi01 .en{
    color: #2E97F2;
    font-family: bistro-script-web, sans-serif; 
    font-size: 24px;
}
.parallaxWrapper{
    position: relative;
    overflow: hidden;
}
.parallaxWrapper .parallaxBackground{
    position: absolute;
    top: 0;
    left: 0;
    height: 500%;
    z-index: -1;
    margin: 0;
    opacity: 0.07;
}
.parallaxWrapper .parallaxBackground img{
    width: auto;
    height: 1500px;
    object-position: center;
    object-fit: cover;
}
/* スマホ */
@media screen and (max-width: 500px) {
    #vision .visionWrapper {
        display: block;
    }
    #vision .visionWrapper .visionWrapperBox {
        margin: 0 10px;
        width: calc(100% - 20px);
    }
    #vision .visionWrapper .canvas{
        width: calc(100% - 40px);
        height: 2px;
        margin: 10px auto 30px;
        transition: 1000ms;
    }
    #vision .visionWrapper .line.top{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: 0;
        height: 2px;
        width: calc(50% - 25px);
    }#vision .visionWrapper .line.bottom{
        position: absolute;
        top: 0;
        right: 0;
        left: auto;
        bottom: 0;
        margin: 0;
        height: 2px;
        width: calc(50% - 25px);
    }
}

/* actions */
#case .actionsWrapper{
    max-width: 780px;
    margin: 0 auto;
    position: relative;
}
#case .actionsWrapper .actionsList {
    margin-bottom: 90px;
    display: flex;
    position: relative;
}
#case .actionsWrapper .actionsList .textBox{
    background-color: #DBDBDB;
    padding: 10px;
}
#case .actionsWrapper .actionsList h3{
    line-height: 1.5;
    width: 100%;
    margin: 0 0 20px;
}
#case .actionsWrapper .actionsList p{
    line-height: 1.5;
    width: 100%;
    margin: 0 0 10px;
    font-size: 14px;
}
#case .actionsWrapper .action1 .textBox{
    position: absolute;
    top: 30px;
    width: 420px;
    padding: 20px 70px 20px 20px;
}
#case .actionsWrapper .action1 .imageBox{
    width: 100%;
    text-align: right;
    z-index: 1;
}
#case .actionsWrapper .action1 .imageBox img{
    width: 300px;
}
#case .actionsWrapper .action2 .textBox{
    position: absolute;
    top: 30px;
    left: 445px;
    width: 300px;
    padding: 20px 20px 20px 30px;
}
#case .actionsWrapper .action2 .imageBox{
    width: 100%;
    padding-bottom: 30px;
    text-align: left;
    z-index: 1;
}
#case .actionsWrapper .action2 .imageBox img{
    width: 450px;
}
#case .actionsWrapper .action3 .textBox{
    position: absolute;
    top: 30px;
    width: 350px;
    padding: 20px 20px 20px 30px;
}
#case .actionsWrapper .action3 .imageBox{
    width: 100%;
    padding-bottom: 30px;
    text-align: right;
    z-index: 1;
}
#case .actionsWrapper .action3 .imageBox img{
    width: 380px;
    height: 380px;
    object-fit: cover;

}
/* タブレット */
@media screen and (max-width: 800px) {
    #case .actionsWrapper .actionsList {
        margin-bottom: 60px;
        display: block;
        position: relative;
    }
    #case .actionsWrapper .action1 .imageBox {
        width: 100%;
        text-align: right;
        z-index: 1;
        margin: 0 auto;
        display: block;
        width: calc(100% - 40px);
        max-width: 350px;
        text-align: center;
        padding: 20px;
        background-color: #DBDBDB;
    }
    #case .actionsWrapper .action1 .textBox{
        position: relative;
        margin: 0 auto;
        width: calc(100% - 40px);
        max-width: 350px;
        padding: 20px;
        top: auto;
        left: auto;
    }
    #case .actionsWrapper .action1 .imageBox img{
        height: 150px;
        width: auto;
    }
    #case .actionsWrapper .action2 .imageBox {
        text-align: right;
        z-index: 1;
        margin: 0 auto;
        display: block;
        width: calc(100% - 40px);
        max-width: 350px;
        text-align: center;
        padding: 20px;
        background-color: #DBDBDB;
    }
    #case .actionsWrapper .action2 .textBox{
        position: relative;
        margin: 0 auto;
        width: calc(100% - 40px);
        max-width: 350px;
        padding: 20px;
        top: auto;
        left: auto;
    }
    #case .actionsWrapper .action2 .imageBox img{
        height: 150px;
        width: auto;
    }
    #case .actionsWrapper .action3 .imageBox {
        width: 100%;
        text-align: right;
        z-index: 1;
        margin: 0 auto;
        display: block;
        width: calc(100% - 40px);
        max-width: 350px;
        text-align: center;
        padding: 20px;
        background-color: #DBDBDB;
    }
    #case .actionsWrapper .action3 .textBox{
        position: relative;
        margin: 0 auto;
        width: calc(100% - 40px);
        max-width: 350px;
        padding: 20px;
        top: auto;
        left: auto;
    }
    #case .actionsWrapper .action3 .imageBox img{
        height: 150px;
        width: auto;
    }
  }

  /* Message */
  #message .messageWrapper{
    max-width: 780px;
    margin: 0 auto 90px;
    position: relative;
    display: flex;
  }
  #message .messageWrapper .imageBox{
    width: calc(50% - 40px);
    margin: 0 auto;
    padding: 20px;
  }
  #message .messageWrapper .imageBox img{
    border-radius: 200px;
}
#message .messageWrapper .textBox{
    margin: 0 auto;
    width: 50%;
}
#message .messageWrapper .textBox h3{
    font-size: 24px;
    margin: 0 auto 30px;
    line-height: 1;
}
#message .messageWrapper .textBox h3 .cap{
    font-size: 14px;
    font-weight: 400;
}
#message .messageWrapper .textBox p{
    font-size: 14px;
}
@media screen and (max-width: 500px) {
    #message .messageWrapper{
        display: block;
        margin: 0 10px 90px;
      }
    #message .messageWrapper .imageBox{
        width: calc(100% - 40px);
        margin: 0 auto;
        padding: 20px;
      }
      #message .messageWrapper .imageBox img{
        border-radius: 200px;
    }
    #message .messageWrapper .textBox{
        margin: 0 auto;
        width: 100%;
    }
}

/* About */
#about .aboutWrapper{
    max-width: 780px;
    margin: 0 auto 90px;
    position: relative;
    display: flex;
}
#about .aboutWrapper .tableWrapper{
    width: calc(50% - 20px);
    margin-right: 20px;
}
#about .aboutWrapper .mapWrapper{
    width: calc(50% - 10px );
    overflow: hidden;
}
#about .aboutWrapper .iframe{
    border: 0;
    height: 300px;

}
@media screen and (max-width: 500px) {
    #about .aboutWrapper .iframe{
        border: 0;
        width: 100%;
        height: 300px;
    }
    #about .aboutWrapper{
        display: block;
    }
    #about .aboutWrapper .tableWrapper{
        width: calc(100% - 10px);
        margin: 0 auto 30px;
    }
    #about .aboutWrapper .mapWrapper{
        width: calc(100% - 10px);
        margin: 0 auto 30px;
        padding: 0;
    }
}
/* Contact */
#contact .formWrapper{
    max-width: 780px;
    margin: 0 auto 90px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    border-radius: 5px;
    padding: 20px;
}
#contact .formWrapper legend{
   font-size: 24px;
   font-weight: bold;
   font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
}
.circularProgressWrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ffffff9c;
    margin: auto;
    opacity: 0.8s;
    visibility: hidden;
    transition: 0.8s;
}
.circularProgressWrapper.active{
    opacity: 1;
    visibility: visible;
    transition: 0.8s;
}

.circularProgressWrapper .circularProgressBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
@media screen and (max-width: 800px) {
    #contact .formWrapper{
        margin: 0 10px 90px;
        width: calc(100% - 40px);
        padding: 10px;
    }
}